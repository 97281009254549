export const es = {
  navigation: {
    home: 'Inicio',
    about: 'Sobre Nosotros',
    contact: 'Contacto',
  },
  hero: {
    title: 'Bienvenido a un mundo de posibilidades',
    subtitle: 'Descubre un lugar con aplicaciones increíble y nuevos desarrollos',
    description: 'Este espacio es un laboratorio de ideas, donde la innovación florece y la tecnología se transforma en realidad. A lo largo de nuestra trayectoria, hemos tenido la fortuna de dar vida a numerosos proyectos, tanto en solitario como en equipo, creando soluciones digitales que no solo satisfacen necesidades, sino que también inspiran, hemos logrado desarrollar aplicaciones que han dejado una huella en la comunidad tecnológica y han sido reconocidas . Estamos convencidos de que el trabajo en equipo y la pasión por la innovación son la clave para construir un futuro mejor. La pregunta que nos impulsa es: ¿qué nuevos horizontes nos esperan en el futuro?',
  },
  features: {
    title: 'Ideas en aplicaciones',
    description: 'Nuestras aplicaciones son el reflejo de gustos personales y la sensacion de solucionar problemas y crear nuevas cosas.',
    items: {
      feature1: 'Aplicaciones nativas',
      feature2: 'Aplicaciones hibridas',
      feature3: 'Aplicaciones web',
    },
    summary: 'Ideas hechas aplicaciones en las cuales se pueden expresar de manera creativa.',
    summary2: 'Proyecto desarrollado en el sector de transporte que facilita realizar cálculos rápidos para la obtención de datos simples pero útiles.',
    images: {
      chart: 'Panel de estadísticas',
      calendar: 'Vista de calendario',
      graph: 'Gráfico analítico',
      metrics: 'Métricas de rendimiento'
    }
  },
  footer: {
    trial: {
      title: '¿Qué ideas convertidas en aplicaciones estarán por venir?',
      description: 'Muy pronto les estaremos mostrando los nuevos proyectos.',
      button: 'Proyectos venideros'
    },
    sections: {
      //company: {
       // title: 'QUIEN SOY',
       // about: 'Sobre mi',
        //careers: 'Carreras',
       // brandCenter: '',
        //blog: 'Blog'
      //},
      // helpCenter: {
        // title: 'CENTRO DE AYUDA',
        // discord: 'Servidor de Discord',
        // twitter: 'Twitter',
        // facebook: 'Facebook',
        // contact: 'Contáctanos'
      // },
      legal: {
        title: 'LEGAL',
        privacy: 'Política de Privacidad',
        licensing: 'Licencias',
        terms: 'Términos'
      },
      download: {
        title: 'DESCARGAR',
        ios: 'iOS',
        android: 'Android',
        windows: 'Windows',
        macos: 'MacOS'
      }
    },
    copyright: {
      text: '',
      flowbite: '',
      and: '',
      tailwind: ''
    }
  },
  worldPotential: {
    title: "Reconocimientos",
    description: "Music Creator ha sido ganadora en los premios HMS App Innovation Contest en la versión 2021.",
    description2: "Melody Creator fue finalista en los premios Huawei de aplicaciones de 2019.",
    items: {
      item1: "Innovación en aplicaciones",
      item2: "Nuevos Usos para hacer de la aplicacion una herramienta mas completa",
      item3: "Interfaz de usuario intuituiva y facil de usar",
      item4: "Actualizacion constante",
      item5: "Mejorando la experiencia de usuario"
    },
    summary: " En la actualidad nuestras aplicaciones como Songs Creator y Melody Creator han alcanzado la cifra de hasta 500.000 descargas lo cual es un gran logro. Asi mismo; gracias a la pasion por el desaorrollo de software, la curiosidad, las ideas y con un ingrediente fundamental que es la mejora continua, podemos concluir que es el mundo tecnologico esta lleno de aventuras y posibilidades .",
    images: {
      worldMap: "World map statistics",
      lineChart: "Growth chart",
      usMap: "US map data"
    }
  }
}