export const en = {
  navigation: {
    home: 'Home',
    about: 'About',
    contact: 'Contact',
  },
  hero: {
    title: 'Welcome to Our World',
    subtitle: 'Discover Amazing Places',
    cta: 'Learn More',
    description: 'This space is an ideas laboratory, where innovation flourishes and technology becomes reality. Throughout our journey, we have been fortunate to bring numerous projects to life, both individually and as a team, creating digital solutions that not only meet needs but also inspire. We have managed to develop applications that have left a mark on the technological community and have been recognized. We are convinced that teamwork and passion for innovation are the key to building a better future. The question that drives us is: what new horizons await us in the future?',
  },
  features: {
    title: 'Applications ideas',
    description: 'Our applications are the reflection of personal tastes and the feeling of solving problems and creating new things.',
    items: {
      feature1: 'Native applications',
      feature2: 'Hybrid applications',
      feature3: 'Web applications',
    },
    summary: 'Ideas made applications where you can express yourself creatively.',
    images: {
      chart: 'Statistics dashboard',
      calendar: 'Calendar view',
      graph: 'Analytics graph',
      metrics: 'Performance metrics'
    },
    summary2: 'Project developed in the transportation sector that facilitates quick calculations to obtain simple but useful data.',
  },
  worldPotential: {
    title: "Reconocimientos",
    description: "Music Creator has been a winner at the HMS App Innovation Contest in the 2021 version. Melody Creator was a finalist in the 2019 Huawei app awards.",
    description2: "Melody Creator was a finalist in the 2019 Huawei app awards.",
    items: {
      item1: "Innovación en aplicaciones",
      item2: "Usos agregados para hacer de la aplicacion una herramienta mas completa",
      item3: "Interfaz de usuario intuituiva y facil de usar",
      item4: "Actualizacion constante",
      item5: "Mejorando la experiencia de usuario"
    },
    summary: "Currently, our applications like Songs Creator and Melody Creator have reached up to 500,000 downloads, which is a great achievement. Likewise, thanks to the passion for software development, curiosity, ideas, and with a fundamental ingredient that is continuous improvement, we can conclude that the technological world is full of adventures and possibilities.",
    images: {
      worldMap: "World map statistics",
      lineChart: "Growth chart",
      usMap: "US map data"
    }
  },
  footer: {
    trial: {
      title: 'What ideas turned into applications are coming?',
      description: 'We will be showing you new projects very soon.',
      button: 'Upcoming projects'
    },
    sections: {
      company: {
        title: 'COMPANY',
        about: 'About',
        careers: 'Careers',
        brandCenter: 'Brand Center',
        blog: 'Blog'
      },
      helpCenter: {
        title: 'HELP CENTER',
        discord: 'Discord Server',
        twitter: 'Twitter',
        facebook: 'Facebook',
        contact: 'Contact Us'
      },
      legal: {
        title: 'LEGAL',
        privacy: 'Privacy Policy',
        licensing: 'Licensing',
        terms: 'Terms'
      },
      download: {
        title: 'DOWNLOAD',
        ios: 'iOS',
        android: 'Android',
        windows: 'Windows',
        macos: 'MacOS'
      }
    },
    copyright: {
      text: '© 2021-2022 Landwind™. All Rights Reserved. Built with',
      flowbite: 'Flowbite',
      and: 'and',
      tailwind: 'Tailwind CSS'
    }
  }
};
